<template>
  <v-card
    class="mx-auto"
    width="100%"
  >
    <v-card-title>
      <v-app-bar
        flat
        class="white pa-0"
        elevate-on-scroll
        dense
      >
        <v-toolbar-title
          data-cy="data_import_title"
        >
          {{ $t('import.title') }}
        </v-toolbar-title>
        <v-spacer />
      </v-app-bar>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-row>
            <v-col>
              <p>{{ $t('import.type.label') }}</p>
              <v-btn-toggle
                v-model="form.type"
                color="primary"
                class="mr-3"
                dense
                mandatory
              >
                <v-btn
                  value="person"
                  data-cy="np_import_btn"
                >
                  {{ $t('import.type.options.person') }}
                </v-btn>

                <v-btn
                  value="company"
                  data-cy="company_import_btn"
                >
                  {{ $t('import.type.options.company') }}
                </v-btn>

                <v-btn
                  value="desktop-migration"
                  v-if="access_menus.toLowerCase().indexOf('solomon') !== -1"
                  data-cy="desktop_migration_btn"
                >
                  {{ 'Desktop Migration' }}
                </v-btn>

                <v-btn
                  value="linksoft/individual"
                  v-if="access_menus.toLowerCase().indexOf('solomon') !== -1"
                  data-cy="solomon_indi_btn"
                >
                  Solomon - Individual
                </v-btn>
              </v-btn-toggle>
              <v-btn
                v-if="['person', 'company'].indexOf(form.type) !== -1"
                text
                color="primary"
                :href="form.type == 'person' ? 'https://drive.google.com/file/d/11y3JiGk5bNhnvkzKMoIDGXpR6AHgHTB6/view?usp=sharing' : 'https://drive.google.com/file/d/14gw5t9F4SF7mTbNBYxh3M1Tu_IFwaWfw/view?usp=sharing'"
                target="_blank"
                data-cy="template_file_btn"
              >
                <v-icon>mdi-download</v-icon>
                {{ $t('import.buttons.download_template') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              data-cy="upload_file_col"
            >
              <v-btn
                color="primary"
                class="mr-3"
                outlined
                @click="onUploadClick"
                :loading="file_selecting || importing"
                data-cy="upload_file_btn"
              >
                <v-icon left>
                  mdi-file-excel
                </v-icon>
                {{ $t('import.buttons.select') }}
              </v-btn>
              <input
                ref="uploader"
                class="d-none"
                type="file"
                :accept="['person', 'company', 'linksoft/individual'].indexOf(form.type) !== -1 ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/msaccess'"
                @change="onFileChanged"
                data-cy="input_file"
              >
              {{ file_selected ? file_selected.name : '' }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                @click="onImport"
                :loading="importing"
                data-cy="import_data_btn"
              >
                {{ $t('import.buttons.start') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-virtual-scroll
            :bench="3"
            :items="import_history"
            max-height="350"
            item-height="46"
          >
            <template v-slot:default="{ item }">
              <v-list-item
                :key="item.updated_date"
                dense
              >
                <v-list-item-action>
                  #{{ item.id }}
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.status }}
                  </v-list-item-title>
                </v-list-item-content>

                <!-- <v-list-item-action>
                                    <v-icon small>
                                        mdi-open-in-new
                                    </v-icon>
                                </v-list-item-action> -->
              </v-list-item>

              <v-divider />
            </template>
          </v-virtual-scroll>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  computed: {
    ...mapGetters('import', {
      import_history: 'import_history'
    }),
    ...mapState('auth', [
      'access_menus'
    ])
  },
  data () {
    return {
      file_selected: null,
      file_selecting: false,
      importing: false,
      form: {
        type: 'person'
      }
    }
  },
  methods: {
    onFileChanged (e) {
      this.file_selected = e.target.files[0]
      console.log(this.file_selected)
      // upload
      // if (this.file_selected) {
      // 	this.dialog_upload = true;
      // }
    },
    onUploadClick () {
      this.file_selecting = true
      this.$nextTick(() => {
        this.file_selected = null
        window.addEventListener('focus', () => { this.file_selecting = false }, { once: true })
        this.$refs.uploader.click()
      })
    },
    onImport () {
      this.importing = true
      this.$store.dispatch('import/importXLSX', { import_type: this.form.type, file: this.file_selected })
        .then((results) => {
          if (results.status === 200 && results.data) {
            return this.$notify({
              type: 'success',
              title: this.$t('import.msg.upload.title'),
              text: this.$t('import.msg.upload.success')
            })
          } else {
            this.$notify({
              type: 'error',
              title: this.$t('import.msg.upload.title'),
              text: this.$t('import.msg.upload.error')
            })
          }
        }).catch((error) => {
          if (error.response) {
            // console.log(error.response)
            this.$notify({
              type: 'error',
              title: this.$t('import.msg.upload.title'),
              text: error.response.data.message || error.message
            })
          } else {
            this.$notify({
              type: 'error',
              title: this.$t('import.msg.upload.title'),
              text: error.message
            })
          }
        }).finally(() => {
          this.file_selected = null
          this.importing = false
        })
    }
  }
}
</script>

<style>

</style>
